import Admin from "../features/admin/routes/page";
import Analytics from "../features/analytics/routes";
import ListBlogs from "../features/blogs/routes/list";
import ManageBlog from "../features/blogs/routes/manage";
import SpecificBlog from "../features/blogs/routes/specific";
import CMS from "../features/cms/routes";
import CouponsList from "../features/coupons/routes/list";
import ManageCoupon from "../features/coupons/routes/manage";
import EventUsers from "../features/eventUsers/routes/list";
import LoyalityPointsList from "../features/loyalityPoints/routes/list";
import LoyalityPointsSettings from "../features/loyalityPoints/routes/settings";
import Notification from "../features/notification/routes";
import Orders from "../features/orders/routes/list";
import Document from "../features/document/routes";
import CountryRegionsList from "../features/plans/routes/countryRegionList";
import PlanSettings from "../features/plans/routes/planSettings";
import ViewPlans from "../features/plans/routes/viewPlans";
import Profile from "../features/profile/routes";
import AddNewPromo from "../features/promos/routes/add";
import PromoList from "../features/promos/routes/list";
import Seo from "../features/seo/routes";
import Users from "../features/users/routes/list";
import vieworder from "../features/vieworder/routes/vieworder";
import { APP_ROUTES } from "../lib/constants";
import Partners from "../features/partners/routes";
import UsersList from "../features/partners/routes/list";
import GenerateVsfEmployeeQr from "../features/partners/routes/employeeForm";
import VFSEmployeesList from "../features/partners/routes/vfs_employees_list";
import PartnerAdminForm from "../features/partners/routes/partnerAdminForm";
import PartnerAdminList from "../features/partners/routes/admitList";
import OrdersList from "../features/partners/routes/ordersList";
import UpsellRecords from "../features/partners/routes/upsellRecords";
import CommbitzConfigurations from "../features/configurations/routes/viewPlans";

interface ProtectedRoutes {
  element: () => JSX.Element;
  path: string;
};

export const protectedRoutes: ProtectedRoutes[] = [
  { element: Users, path: APP_ROUTES.USERS },
  { element: Admin, path: APP_ROUTES.ADMIN },
  { element: CountryRegionsList, path: APP_ROUTES.PLANS },
  { element: PlanSettings, path: APP_ROUTES.PLANS_SETTINGS },
  { element: ViewPlans, path: APP_ROUTES.REGIONS_PLANS },
  { element: ViewPlans, path: APP_ROUTES.COUNTRY_PLANS },
  { element: PromoList, path: APP_ROUTES.PROMOS },
  { element: AddNewPromo, path: APP_ROUTES.UPDATE_PROMO },
  { element: AddNewPromo, path: APP_ROUTES.NEW_PROMO },
  { element: CouponsList, path: APP_ROUTES.COUPONS },
  { element: ManageCoupon, path: APP_ROUTES.UPDATE_COUPON },
  { element: ManageCoupon, path: APP_ROUTES.NEW_COUPON },
  { element: LoyalityPointsList, path: APP_ROUTES.LOYALITY_POINTS },
  { element: LoyalityPointsSettings, path: APP_ROUTES.LOYALITY_POINTS_SETTINGS },
  { element: ManageBlog, path: APP_ROUTES.UPDATE_BLOG },
  { element: ListBlogs, path: APP_ROUTES.BLOGS },
  { element: ManageBlog, path: APP_ROUTES.NEW_BLOG },
  { element: SpecificBlog, path: APP_ROUTES.SPECIFIC_BLOG },
  { element: Document, path: APP_ROUTES.VIEW_DOCUMENTS },
  { element: Notification, path: APP_ROUTES.NOTIFICATIONS },
  { element: Profile, path: APP_ROUTES.PROFILE },
  { element: CMS, path: APP_ROUTES.CMS },
  { element: Analytics, path: APP_ROUTES.ANALYTICS },
  { element: Orders, path: APP_ROUTES.ORDER },
  { element: EventUsers, path: APP_ROUTES.EVENT_USERS },
  { element: Seo, path: APP_ROUTES.SEO_USERS },
  { element: Partners, path: APP_ROUTES.PARTNERS },
  { element: UsersList, path: APP_ROUTES.PARTNERS_USER },
  { element: OrdersList, path: APP_ROUTES.PARTNERS_USER_ORDER_LIST },
  { element: GenerateVsfEmployeeQr, path: APP_ROUTES.VSF_EMPLOYEE_QR },
  { element: VFSEmployeesList, path: APP_ROUTES.VSF_EMPLOYEES_LIST },
  { element: PartnerAdminForm, path: APP_ROUTES.PARTNER_ADMIN_CREATE_FORM },
  { element: PartnerAdminList, path: APP_ROUTES.PARTNER_ADMIN_LIST },
  { element: UpsellRecords, path: APP_ROUTES.PARTNER_UPSELL_RECORDS },
  { element: CommbitzConfigurations, path: APP_ROUTES.CONFIGRUATIONS }

];

export default protectedRoutes;