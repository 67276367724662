import React, { useEffect, useState } from "react";
import { Modal, Form } from "react-bootstrap";
import { toast } from "react-toastify";

import styles from "../Profile/style.module.css";
import { useFormik } from "formik";
import { updateProfile } from "../../../features/profile/api";
import { CONSTANTS } from "../../../lib/constants/messages";
// import passwordSchema from "./validation";
import { ModalTypes } from "../../../interfaces";
import { addUpsellForm, getConfigrations } from "../../../features/partners/api";
import addUpsellValidation from "./validation";
import style from "./style.module.css"

const UpsellForm = (props: ModalTypes.UpsellFormType) => {
  const [commission, setCommission] = useState()

  const profileFormik = useFormik({
    initialValues: {
      planName: "",
      upsellDate: "",
      upsellAmount: "",
      upsellAmountCurrency: "INR",
    },
    enableReinitialize: true, // Allow reinitialization when props change
    validationSchema: addUpsellValidation,
    onSubmit: (values, { setSubmitting }) => {

      const payload: any = {
        planName: values.planName,
        upsellDate: values.upsellDate,
        upsellAmount: values.upsellAmount,
        upsellAmountCurrency: "string",
        orderId: props.orderId,
      };

      toast.promise(addUpsellForm(payload), {
        pending: {
          render() {
            return CONSTANTS.PROFILE.UPDATING;
          },
        },
        success: {
          render() {
            setSubmitting(false);

            profileFormik.resetForm();
            props.setShow(false);
            return CONSTANTS.PROFILE.SUCCESS;
          },
        },
        error: {
          render(response: any) {
            setSubmitting(false);
            return response.data.data.message;
          },
        },
      });
    }
  });
  useEffect(() => {
    getConfigrations().then((data) => {
      setCommission(data?.data?.niyoCommission)
    }).catch((data) => {
      toast.error(data?.data?.message || "Something went wrong!!")
    })
  }, [])
  return (
    <Modal show={props.show} onHide={() => props.setShow(false)}>
      <div>
        <Modal.Header className={styles.modalDialog} closeButton>
          <Modal.Title>Upsell Form</Modal.Title>
        </Modal.Header>
        <Modal.Body className={styles.modalContent}>
          <div className={styles.changePasswordModal}>
            <Form onSubmit={profileFormik.handleSubmit}>
              <div className={styles.formGroup}>
                <label>Plan Name</label>
                <input
                  type="text"
                  name="planName"
                  placeholder="Enter Plan Name"
                  value={profileFormik.values.planName}
                  onChange={profileFormik.handleChange}
                  onBlur={profileFormik.handleBlur}
                />
                {profileFormik.touched.planName &&
                  profileFormik.errors.planName && (
                    <div className={style.error}>
                      {profileFormik.errors.planName}
                    </div>
                  )}
              </div>
              <div className={styles.formGroup}>
                <label>Upsell Date</label>
                <input
                  type="date"
                  name="upsellDate"
                  value={profileFormik.values.upsellDate}
                  onChange={profileFormik.handleChange}
                  onBlur={profileFormik.handleBlur}
                />
                {profileFormik.touched.upsellDate &&
                  profileFormik.errors.upsellDate && (
                    <div className={style.error}>
                      {profileFormik.errors.upsellDate}
                    </div>
                  )}
              </div>

              <div className={styles.formGroup}>
                <label>Upsell Amount(INR)</label>
                <input
                  type="text"
                  placeholder="Enter Upsell Amount"
                  name="upsellAmount"
                  value={profileFormik.values.upsellAmount}
                  onChange={profileFormik.handleChange}
                  onBlur={profileFormik.handleBlur}
                />
                {profileFormik.touched.upsellAmount &&
                  profileFormik.errors.upsellAmount && (
                    <div className={style.error}>
                      {profileFormik.errors.upsellAmount}
                    </div>
                  )}
              </div>
              <div className={styles.formGroup}>
                <label>Niyo Commission</label>
                <input
                  type="text"
                  placeholder="Enter Upsell Amount"
                  name="upsellAmountCurrency"
                  value={commission} readOnly />
              </div>
              <div className={styles.formGroup}>
                <input type="submit" value="Save" />
              </div>
            </Form>
          </div>
        </Modal.Body>
      </div>
    </Modal>
  );
};

export default UpsellForm;
