import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useFormik } from "formik";

import styles from "./style.module.css";
import { toast } from "react-toastify";
import { CONSTANTS } from "../../lib/constants/messages";
import { PlanTypes } from "../../interfaces";
import { planDetailsSchema } from "../../features/plans/validations";
import { updatePopularCountries } from "../../features/plans/api";
import { Toggle } from "../../components";
import { listCountries } from "../../features/promos/api";
import Select from "react-select";

interface Props {
  show: boolean;
  handleClose: () => void;
  planDetails?: PlanTypes.PlanDetails;
  iso: string;
};

const PlanDetailsSettings = (props: Props) => {

  const [countries, setCountries] = useState<{ value: string, label: string }[]>([]);
  const [selectedCountries, setSelectedCountries] = useState<{ value: string, label: string }[]>([]);

  const settingsFormik = useFormik({
    initialValues: {
      network: "",
      planType: "",
      otherInfo: "",
      activationPolicy: "",
    },
    onSubmit: (values) => {
      const popularCountries = selectedCountries?.length > 0 ? selectedCountries?.map((item) => (item.value)) : [];
      let payload = { ...values, countryId: props?.planDetails?._id, popularCountries };
      updateSettings(payload);
    },
    validationSchema: planDetailsSchema
  });

  const updateDocumentRequirement = (event: any) => {
    const payload = {
      documentsRequired: !props.planDetails?.documentsRequired as boolean,
      countryId: props?.planDetails?._id,
    };

    updateSettings(payload);
  };


  const updateSettings = (payload: any) => {
    toast.promise(
      updatePopularCountries(payload),
      {
        pending: {
          render() {
            return CONSTANTS.PLAN_DETAILS.UPDATING;
          },
        },
        success: {
          render() {
            settingsFormik.setSubmitting(false);
            closePopup();

            settingsFormik.resetForm();
            return CONSTANTS.PLAN_DETAILS.UPDATE_PLAN_DETAILS_SUCCESS;
          },
        },
        error: {
          render() {
            return CONSTANTS.PLAN_DETAILS.UPDATE_PLAN_DETAILS_FAILED;
          },
        },
      });
  };

  const closePopup = () => {
    props.handleClose();
  };

  useEffect(() => {
    if (props.planDetails && Object.keys(props.planDetails).length) {
      settingsFormik.setValues({
        activationPolicy: props.planDetails.activationPolicy,
        otherInfo: props.planDetails.otherInfo,
        planType: props.planDetails.planType,
        network: props.planDetails.network
      });
    }

    listCountries({ limit: 1000 }).then(res => {
      const countries = res.data.countries
        .map(item => ({ label: item.name, value: item._id }));

      const selectedCountries = countries?.filter(item => props?.planDetails?.popularCountries.includes(item.value));
     setSelectedCountries(selectedCountries);

      setCountries(countries);
    });

  }, [props.planDetails]);

  return (
    <Modal centered size="xl" show={props.show} onHide={props.handleClose}>
      <Modal.Header className={styles.modHead}>
        <Modal.Title>Settings</Modal.Title>
      </Modal.Header>
      <Modal.Body className={styles.faqContent}>
        <div className="mb-3">
          <label className="w-100 mb-1">
            Network
            {
              settingsFormik.touched.network && settingsFormik.errors.network ?
                <span className="error">{settingsFormik.errors.network}</span>
                :
                null
            }
          </label>
          <input
            className={styles.input}
            type="text"
            placeholder="Enter Network Details"
            name="network"
            onChange={settingsFormik.handleChange}
            onBlur={settingsFormik.handleBlur}
            value={settingsFormik.values.network}
          />
        </div>
        <div className="mb-3">
          <label className="w-100 mb-1">
            Plan Type
            {
              settingsFormik.touched.planType && settingsFormik.errors.planType ?
                <span className="error">{settingsFormik.errors.planType}</span>
                :
                null
            }

          </label>
          <input
            className={styles.input}
            type="text"
            placeholder="Enter Plan Type Details"
            name="planType"
            onChange={settingsFormik.handleChange}
            onBlur={settingsFormik.handleBlur}
            value={settingsFormik.values.planType}
          />
        </div>
        <div className="mb-3">
          <label className="w-100 mb-1">
            Other
            {
              settingsFormik.touched.otherInfo && settingsFormik.errors.otherInfo ?
                <span className="error">{settingsFormik.errors.otherInfo}</span>
                :
                null
            }

          </label>
          <input
            className={styles.input}
            type="text"
            placeholder="Enter Plan Type Details"
            name="otherInfo"
            onChange={settingsFormik.handleChange}
            onBlur={settingsFormik.handleBlur}
            value={settingsFormik.values.otherInfo}
          />
        </div>
        <div className="mb-3">
          <Select
            options={countries as any}
            isMulti
            onChange={(values: any) => {
              setSelectedCountries(values);
            }}
            value={selectedCountries}
            isClearable
          />
        </div>

        <div className="mb-3">
          <label className="w-100 mb-1">
            Activation Policy
            {
              settingsFormik.touched.activationPolicy && settingsFormik.errors.activationPolicy ?
                <span className="error">{settingsFormik.errors.activationPolicy}</span>
                :
                null
            }

          </label>
          <textarea
            className={styles.textarea}
            placeholder="Enter Activation Policy Details"
            name="activationPolicy"
            onChange={settingsFormik.handleChange}
            onBlur={settingsFormik.handleBlur}
            value={settingsFormik.values.activationPolicy}
          ></textarea>
        </div>

        <div className="mb-3 d-flex justify-content-between align-items-center">
          <div>
            <label className="w-100 mb-0">Documents required ?</label>
            <label className={styles.subLabel}>Are you sure you {props.planDetails?.documentsRequired ? "don't" : ""} want to allow users to upload document while buying ESIMS. </label>
          </div>

          <Toggle handleChange={updateDocumentRequirement} value={props.planDetails?.documentsRequired || false} />
        </div>
      </Modal.Body>
      <Modal.Footer className={styles.modFoter}>
        <button type="button" className={styles.close} onClick={closePopup}>Close</button>
        <button type="submit" className={styles.button} onClick={settingsFormik.submitForm}>Save Changes</button>
      </Modal.Footer>
    </Modal>
  );
};

export default PlanDetailsSettings;