import React, { useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";

import styles from "./style.module.css";
import commonStyles from "../../lib/common/common.module.css";
import { APP_ROUTES, LOCAL_CONSTANTS } from "../../lib/constants";
import storage from "../../utils/storage";
import { IMAGES } from "../../assets/images";

const Sidebar = () => {
  const location = useLocation();
  const navigate = useNavigate();


  const logOut = () => {
    storage.clearToken();

    navigate(APP_ROUTES.LOGIN);
  };
  const roles = window.localStorage.getItem("admin_roles")
  const routAccess = (roles !== null && LOCAL_CONSTANTS.ROLES.NIYO_USER === Number(roles)) || (roles !== null && LOCAL_CONSTANTS.ROLES.VFS_USER === Number(roles))

  // useEffect(()=>{
  //   if(routAccess && location.pathname?.split("/")?.[1] == "partners") {
  //     console.log(1)
  //     navigate(APP_ROUTES.PARTNERS);
  //   }
  // },[roles])

  return (
    <div className={`${commonStyles.sidebar} `}>
      <div className={styles["top-logo"]}>
        <img
          className="cursor-pointer"
          onClick={() => navigate(APP_ROUTES?.USERS)}
          height={100}
          width={200}
          src={IMAGES.Logo}
          alt="logo"
        />
      </div>
      <ul>
        {/* <li className={location.pathname === APP_ROUTES.DASHBOARD ? styles.active: ''}>
					<Link to={APP_ROUTES.DASHBOARD}>
						<span><img height={20} width={20} src={IMAGES.SIDEBAR.DASHBOARD} alt="dashboard" /></span>
						Dashboard
					</Link>
				</li> */}
        {!routAccess &&
          <>
            <li className={location.pathname === APP_ROUTES.USERS ? commonStyles.active : ""}>
              <Link to={APP_ROUTES.USERS}>
                <span>
                  <img
                    height={20}
                    width={20}
                    src={IMAGES.SIDEBAR.UserSidebar}
                    alt="users"
                  />
                </span>
                Users
              </Link>
            </li>
            <li className={location.pathname === APP_ROUTES.CMS ? commonStyles.active : ""}>
              <Link to={APP_ROUTES.CMS}>
                <span>
                  <img
                    height={20}
                    width={20}
                    src={IMAGES.SIDEBAR.CMSSidebar}
                    alt="cms"
                  />
                </span>
                CMS
              </Link>
            </li>
            <li className={location.pathname.startsWith("/plans") ? commonStyles.active : ""}>
              <Link to={APP_ROUTES.PLANS}>
                <span>
                  <img
                    height={20}
                    width={20}
                    src={IMAGES.SIDEBAR.PlansSidebar}
                    alt="cms"
                  />
                </span>
                Plans
              </Link>
            </li>
            <li className={location.pathname.startsWith("/orders") ? commonStyles.active : ""}>
              <Link to={APP_ROUTES.ORDER}>
                <span>
                  <img
                    height={20}
                    width={20}
                    src={IMAGES.SIDEBAR.PaymentsSidebar}
                    alt="order"
                  />
                </span>
                Orders
              </Link>
            </li>
            <li className={location.pathname.startsWith("/promos") ? commonStyles.active : ""}>
              <Link to={APP_ROUTES.PROMOS}>
                <span>
                  <img
                    height={20}
                    width={20}
                    src={IMAGES.SIDEBAR.PromosSidebar}
                    alt="promos"
                  />
                </span>
                Promos
              </Link>
            </li>
            <li className={location.pathname.startsWith("/coupons") ? commonStyles.active : ""}>
              <Link to={APP_ROUTES.COUPONS}>
                <span>
                  <img
                    height={20}
                    width={20}
                    src={IMAGES.SIDEBAR.CouponsSidebar}
                    alt="coupons"
                  />
                </span>
                Coupons
              </Link>
            </li>
            {/* <li className={location.pathname.startsWith("/loyalityPoints") ? commonStyles.active : ""}>
          <Link to={APP_ROUTES.LOYALITY_POINTS}>
            <span>
              <img
                height={20}
                width={20}
                src={IMAGES.SIDEBAR.LoyalityPointsSidebar}
                alt="loyalityPoints"
              />
            </span>
            Loyality Points
          </Link>
        </li> */}

            <li className={location.pathname.startsWith("/blogs") ? commonStyles.active : ""}>
              <Link to={APP_ROUTES.BLOGS}>
                <span>
                  <img
                    height={20}
                    width={20}
                    src={IMAGES.SIDEBAR.BlogsSidebar}
                    alt="blogs"
                  />
                </span>
                Blogs
              </Link>
            </li>
            <li className={location.pathname === APP_ROUTES.NOTIFICATIONS ? commonStyles.active : ""}>
              <Link to={APP_ROUTES.NOTIFICATIONS}>
                <span>
                  <img
                    height={20}
                    width={20}
                    src={IMAGES.SIDEBAR.NotificationSidebar}
                    alt="promos"
                  />
                </span>
                Notifications
              </Link>
            </li>
            <li className={location.pathname === APP_ROUTES.ANALYTICS ? commonStyles.active : ""}>
              <Link to={APP_ROUTES.ANALYTICS}>
                <span>
                  <img
                    height={20}
                    width={20}
                    src={IMAGES.SIDEBAR.AnalyticsSidebar}
                    alt="analytics"
                  />
                </span>
                Report And Analytics
              </Link>
            </li>
            <li className={location.pathname === APP_ROUTES.PROFILE ? commonStyles.active : ""}>
              <Link to={APP_ROUTES.PROFILE}>
                <span>
                  <img
                    height={20}
                    width={20}
                    src={IMAGES.SIDEBAR.ProfileSidebar}
                    alt="profile"
                  />
                </span>
                Profile
              </Link>
            </li>
            <li className={location.pathname === APP_ROUTES.EVENT_USERS ? commonStyles.active : ""}>
              <Link to={APP_ROUTES.EVENT_USERS}>
                <span>
                  <img
                    height={20}
                    width={20}
                    src={IMAGES.SIDEBAR.UserSidebar}
                    alt="event-users"
                  />
                </span>
                Commbitz Events User
              </Link>
            </li>
            {/* cls */}
          </>
        }
        <li className={location.pathname === APP_ROUTES.PARTNERS ? commonStyles.active : ""}>
          <Link to={APP_ROUTES.PARTNERS}>
            <span>
              <img
                height={20}
                width={20}
                src={IMAGES.SIDEBAR.UserSidebar}
                alt="Partners"
              />
            </span>
            Partners
          </Link>
        </li>
        {/* {LOCAL_CONSTANTS.ROLES.COMMBITZ_USER === Number(roles) &&
          <li className={location.pathname === APP_ROUTES.CONFIGRUATIONS ? commonStyles.active : ""}>
            <Link to={APP_ROUTES.CONFIGRUATIONS}>
              <span>
                <img
                  height={20}
                  width={20}
                  src={IMAGES.SIDEBAR.UserSidebar}
                  alt="Configuration"
                />
              </span>
              Configuration
            </Link>
          </li>
        } */}

        {/* <li className={location.pathname === APP_ROUTES.PARTNERS_ADMIN ? commonStyles.active : ""}>
          <Link to={APP_ROUTES.PARTNERS_ADMIN}>
            <span>
              <img
                height={20}
                width={20}
                src={IMAGES.SIDEBAR.UserSidebar}
                alt="Partners"
              />
            </span>
            Partners Admins
          </Link>
        </li> */}
        <li onClick={logOut} className={styles.logout}>
          <Link to="#">
            <span>
              <img
                height={20}
                width={20}
                src={IMAGES.SIDEBAR.LogoutSidebar}
                alt="logout"
              />
            </span>
            Logout
          </Link>
        </li>
      </ul>
    </div>
  );
};

export default Sidebar;
