import { useEffect, useState } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserAltSlash } from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";
import Select from "react-select";

import styles from "../styles/style.module.css";
import commonStyles from "../../../lib/common/common.module.css";
import { LOCAL_CONSTANTS } from "../../../lib/constants";
import { UserTypes } from "../../../interfaces";
import { list } from "../api";
import Layout from "../../../ui/layout";
import { IMAGES } from "../../../assets/images";
import { DropDownMenu, ActionButton, Pagination, Popup } from "../../../ui";
import moment from "moment";

const EventUsers = () => {
  const [pagination, setPagination] = useState({
    skip: 0,
    limit: LOCAL_CONSTANTS.limitCount,
  });

  const [users, setUsers] = useState<{ list: UserTypes.User[]; count: number }>(
    {
      list: [],
      count: 0,
    }
  );

  const [activeTab, setActiveTab] = useState<number>(1);

  const [loading, setLoading] = useState<boolean>(true);

  const listUsers = (pageIndex = pagination.skip) => {
    let query: any = {
      page: 1 + pageIndex,
      limit: pagination.limit,
    };
    if (activeTab == 1) {
      query.search = "Bali";
    }
    if (activeTab == 2) {
      query.search = "ITB India";
    }
    if (activeTab == 3) {
      query.search = "ITB Singapur";
    }
    list(query).then(({ data }) => {
      setUsers({ list: data.users, count: data.count });
      setLoading(false);
    });
  };

  useEffect(() => {
    setLoading(true);
    const debounceSearch = setTimeout(() => {
      listUsers();
    }, 500);
    return () => {
      clearTimeout(debounceSearch);
    };
  }, [pagination, activeTab]);
  return (
    <Layout title="Event Users">
      <div className={commonStyles.tableData}>
        <div className={commonStyles.mainTabbing}>
          <div className="leftTabbing">
            <div className={commonStyles.topCms}>
              <ul>
                <li
                  className={activeTab === 1 ? commonStyles.active : ""}
                  onClick={() => {
                    setActiveTab(1);
                  }}
                >
                  <p>Bali</p>
                </li>
                <li
                  className={activeTab === 2 ? commonStyles.active : ""}
                  onClick={() => {
                    setActiveTab(2);
                  }}
                >
                  <p>ITB India</p>
                </li>
                <li
                  className={activeTab === 3 ? commonStyles.active : ""}
                  onClick={() => {
                    setActiveTab(3);
                  }}
                >
                  <p>ITB Singapore</p>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className={styles.scrollTable}>
          <table>
            <thead>
              <tr>
                <th>Name</th>
                <th>Email</th>
                <th>PHone no</th>
                <th>Country</th>
                <th>Travelling Date</th>
                <th>Return Date</th>
                {/* <th>No Of Person</th> */}
                <th>Handset Model No</th>
              </tr>
            </thead>
            <tbody>
              {loading && (
                <tr>
                  <td colSpan={7} align="center" className={styles.loadingText}>
                    {LOCAL_CONSTANTS.loading}
                  </td>
                </tr>
              )}
              {!loading && users.list.length === 0 && (
                <tr>
                  <td colSpan={7} align="center">
                    No User Found
                  </td>
                </tr>
              )}
              {!loading &&
                users.list.map((item: any) => (
                  <tr key={item._id}>
                    <td>
                      {item?.firstName} {item?.lastName}
                    </td>
                    <td>{item.email}</td>
                    <td>
                      {item.countryCode} {item.phoneNumber}
                    </td>
                    <td>{item.travellingCountry}</td>
                    <td>
                      {moment(item.travellingDate).format(
                        LOCAL_CONSTANTS.dateFormat
                      )}
                    </td>
                    <td>
                      {moment(item.returnDate).format(
                        LOCAL_CONSTANTS.dateFormat
                      )}
                    </td>
                    {/* <td>{item.noOfPerson}</td> */}
                    <td>{item.handsetModelNo}</td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
        <Pagination
          paginationState={pagination}
          setPaginationState={setPagination}
          count={users.count}
        />
      </div>
    </Layout>
  );
};

export default EventUsers;
